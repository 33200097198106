<template>
    <form action @submit.prevent="login">
        <div class="d-flex flex-column justify-content-between text-dark-gray recover__size" style="gap: 43px;">
            <div class="image-size mx-auto">
                <img src="/img/login/icon-user.png" alt="" class="h-100 w-100 rounded-circle bg-white shadow-inner obj-none">
            </div>
            <div class="text-center f-18 mx-auto recover-text__size">
                <p>
                    Su usuario ha sido enviado a su correo
                </p>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-general px-5 f-600" type="submit" @click="navigateTo({ name: 'login' })">Aceptar</button>
            </div>
            <div class="d-flex flex-column text-center w-fit-content mx-auto" >
                <el-button 
                type="text" 
                class="text-dark-gray underline f-18" 
                @click="goTo({ name: 'login' })"
                >
                    Iniciar sesión
                </el-button>
                <el-button type="text" class="text-dark-gray underline f-18" @click="navigateTo({ name: 'recover', params: {tipo: 'clave'} })">
                    ¿Olvidó su contraseña?
                </el-button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    middleware: 'guest',
    layout:'login',
    data(){
        return {
            email: ''
        }
    },
    mounted(){
        this.$try(() => {
            this.email = this.$route.params.email

            if(!this.email) this.navigateTo({name: 'login'});
        });
    },
}
</script>

<style scoped lang="scss">
.recover{
    &__size{
        width: 350px;
    }
    &-text__size{
        width: 250px
    }
}

</style>